import ModalPopUp from './modal';
import attentionImage from '../../images/attention.svg?url';
import { UAParser } from 'ua-parser-js';

const DEFAULT_LANGUAGE = 'en';

function getCurrentLocale() {
  if (typeof document === 'undefined') {
    return DEFAULT_LANGUAGE;
  }
  return document.querySelector('html').getAttribute('lang');
}

function getPartnerWidgetTheme() {
  const element = document.querySelector('[data-widget-theme]');

  if (element === null) {
    return null;
  }

  return element.dataset.widgetTheme;
}

function getLocaleDirection() {
  return document.querySelector('html').getAttribute('dir') || 'ltr';
}

function getUrlParam(name) {
  var e;
  var re = /([^&=]+)=?([^&]*)/g;
  var search = window.location.search.substr(1);

  while ((e = re.exec(search))) {
    if (null !== e && e[1] == name) {
      return e[2];
    }
  }

  return '';
}

function getUriPrefix() {
  const CURRENT_LANGUAGE = getCurrentLocale();

  return CURRENT_LANGUAGE !== DEFAULT_LANGUAGE ? '/' + CURRENT_LANGUAGE : '';
}

function isMobileLayout() {
  return window.innerWidth < 1000;
}

function isMobileUserAgent(){
  const parser = new UAParser();
  return parser.getDevice().type === 'mobile';
}

function showPopup(title, message, size = 'sm') {
  const popup = document.getElementById('generalPopUpModal');
  const modalBody = popup.querySelector('.modal-body');
  // cleanup before showing
  modalBody.innerHTML = '';

  const illustration = document.createElement('img');
  illustration.setAttribute('src', attentionImage);
  illustration.setAttribute('alt', 'illustration');
  illustration.className = 'timeout-popup__illustration';

  const titleBlock = document.createElement('div');
  titleBlock.className = 'js-popup__title';
  titleBlock.innerHTML = title;

  const messageBlock = document.createElement('p');
  messageBlock.className = 'js-popup__msg';
  messageBlock.innerHTML = message;

  const popupInner = document.createElement('div');
  popupInner.className = 'js-popup__inner';
  popupInner.append(illustration);
  popupInner.append(titleBlock);
  popupInner.append(messageBlock);
  modalBody.append(popupInner);

  const modal = popup.querySelector('.modal');
  modal.classList.remove('modal--lg');
  modal.classList.remove('modal--sm');
  modal.classList.add('modal--' + size);

  ModalPopUp.showModal('generalPopUpModal');
}

function showInternalServerErrorPopup() {
  showPopup(msg_server_error_title, msg_server_error_body);
}

const ElementToggle = {
  hide(element) {
    element.style.display = 'none';
  },
  show(element) {
    element.style.display = 'block';
  },
};

export {
  getUrlParam,
  getUriPrefix,
  isMobileLayout,
  showInternalServerErrorPopup,
  showPopup,
  getCurrentLocale,
  getLocaleDirection,
  getPartnerWidgetTheme,
  ElementToggle,
  isMobileUserAgent,
};
